
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { searchPaymentV2 } from "@/api/payment.api";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { getPanel } from "@/api/panel.api";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    paymentTypes: any[];
    adjustmentReasonCodes: any[];
    providers: any[];
    datesList: { keyword: number; name: string }[];
    transactionTypes: any[];
    facilities: { id: string }[];
  };
  filter: {
    dateType: number;
    startDate: Date | string | null;
    endDate: Date | string | null;
    providerId: string | null;
    transactionType: number[] | null;
    paymentType: number[] | null;
    payer: number[] | null;
  };
  showInactive: boolean;
  modalPaymentId;
  modal;
}
export default defineComponent({
  name: "PatientTransactionsList",
  components: { PaginationUi, Multiselect, NoteComponent },
  setup() {
    const store = useStore();
    const router = useRouter();
    const orderList = [
      {
        name: "Transaction ID",
        key: "paymentId",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Patient",
        key: "patient.fullName",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Entry Date",
        key: "entryDate",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payment Date",
        key: "paymentDate",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "A/R Date",
        key: "accountingDate",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payment Amount",
        key: "totalAmount",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payer",
        key: "payerId",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Unapplied Credit Balance",
        key: "unappliedCredit",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Transaction Type",
        key: "type",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Payment Type",
        key: "paymentType",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Adjustment Reason",
        key: "adjustmentReason",
        isFilter: true,
        keyword: undefined,
      },
      {
        name: "Ref #",
        key: "reference",
        isFilter: true,
        keyword: undefined,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "paymentId",
      IsAdvancedOrderBy: false,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      filter: {
        dateType: 1,
        startDate: null,
        endDate: null,
        providerId: null,
        transactionType: null,
        paymentType: null,
        payer: null,
      },
      dict: {
        adjustmentReasonCodes: [],
        paymentTypes: [],
        providers: [],
        transactionTypes: [],
        datesList: [
          { keyword: 0, name: "Service Date" },
          { keyword: 1, name: "Entry Date" },
          { keyword: 2, name: "Payment Date" },
          { keyword: 3, name: "A/R Date" },
        ],
        facilities: [],
      },
      modalPaymentId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "paymentId";
      data.IsAdvancedOrderBy = false;
      data.isDecr = true;
      data.filter = {
        dateType: 1,
        startDate: null,
        endDate: null,
        providerId: null,
        transactionType: null,
        paymentType: null,
        payer: null,
      };

      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Payment Management");

      data.dict.transactionTypes = store.getters.allTransactionTypes.sort(
        (a, b) => (a.description > b.description ? 1 : -1)
      );
      data.dict.paymentTypes = store.getters.allPaymentTypes.sort((a, b) =>
        a.description > b.description ? 1 : -1
      );
      data.dict.adjustmentReasonCodes = store.getters.allAdjustmentReasonCodes;
      const panel = await getPanel();
      data.dict.providers = panel.providers.filter((provider) => {
        return provider.attending || provider.billing || provider.rendering;
      });
      data.dict.facilities = [{ id: null, name: "Self" }, ...panel.facilities];

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        DateType: data.filter.dateType,
        StartDate: data.filter.startDate,
        EndDate: data.filter.endDate,
        TransactionType: data.filter.transactionType,
        PaymentType: data.filter.paymentType,
        Payer: data.filter.payer,
      };

      const res = await searchPaymentV2(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function postPayment() {
      router.push({ path: "patientPayment", query: { type: 0 } });
    }

    function postAdjustment() {
      router.push({ path: "patientPayment", query: { type: 1 } });
    }

    function applyCredits() {
      router.push({ path: "unappliedCredits" });
    }

    function refundCredits() {
      router.push({ path: "patientRefund" });
    }

    function selectNotes(payment) {
      data.modalPaymentId = payment.id;
      data.modal = true;
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getNow,
      postPayment,
      postAdjustment,
      applyCredits,
      refundCredits,
      selectNotes,
    };
  },
});
